import { BgImage } from '@flyer/components/next';
import { m } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from '@flyer/components';
import MotionImage from '@/components/common/MotionImage';
import { DinRoundProFont } from '@/lib/utils';
import { useScaleByMinWidth } from '@/components/speaking/ket-pet/constants/common';
import background from './assets/bg.png';
import checkBtn from './assets/check.png';
import claimActiveBtn from './assets/claim-active.png';
import claimDisabledBtn from './assets/claim-disabled.png';
import closeIcon from './assets/close.png';
import coinsImg from './assets/coins.png';
import diamondsImg from './assets/diamonds.png';
import shineImg from './assets/shine.png';
import bigShineImg from './assets/big-shine.png';
import { graphql } from '@/__generated__';
import { useAuth } from '@/providers/auth';

const DailyRewards = graphql(`
  query DailyRewards {
    dailyRewards {
      coin
      dailyRewardId
      dayNumber
      diamond
      id
      viewerClaimed
    }
  }
`);

const ViewerDailyRewardClaim = graphql(`
  mutation ViewerDailyRewardClaim {
    viewerDailyRewardClaim {
      userErrors {
        code
        message
      }
      user {
        diamond
        id: userId
      }
      dailyReward {
        id
        viewerClaimed
      }
      learnerProfile {
        id: learnerProfileId
        coin
        canClaimDailyReward
      }
    }
  }
`);

function NormalDayGift({
  index,
  isToday,
  isClaimed,
  numberOfCoins,
  numberOfDiamonds,
}: {
  index: number;
  isToday?: boolean;
  isClaimed?: boolean;
  numberOfCoins?: number;
  numberOfDiamonds?: number;
}) {
  const { t } = useTranslation('common');
  return (
    <m.div
      className={twMerge(
        'bg-[#9A66EB] border-[3px] border-[#B687FF] w-[172px] h-[116px]',
        'flex items-center flex-col pt-3 rounded-[30px] relative overflow-hidden select-none',
      )}
    >
      {!isClaimed && (
        <m.div
          className="absolute w-[116x] h-[116px] rounded-full overflow-hidden -top-[0px]"
          animate={{ rotate: [0, 360] }}
          transition={
            isToday ? { duration: 8, repeat: Number.POSITIVE_INFINITY, ease: 'linear' } : undefined
          }
        >
          <Image src={shineImg} alt="" className="w-full h-full" />
        </m.div>
      )}
      <h6 className="text-[#5F2CAD] text-[24px] leading-5 font-bold">
        {isToday ? t('daily_gift.today') : `${t('daily_gift.day')} ${index}`}
      </h6>
      {isClaimed ? (
        <Image src={checkBtn} alt="" className="w-[64px] h-[45px] mt-4" />
      ) : (
        <div className="mt-2 relative flex-1 w-full flex justify-center">
          {numberOfCoins ? (
            <Image src={coinsImg} alt="" className="w-[58px] h-[51px]" />
          ) : (
            <Image src={diamondsImg} alt="diamonds" className="w-[108px] h-[83px]" />
          )}
          <span className="text-[#5F2CAD] text-[22px] leading-5 absolute bottom-2 right-7">
            x{numberOfCoins || numberOfDiamonds}
          </span>
        </div>
      )}
    </m.div>
  );
}

function FinalDayGift({
  index = 7,
  numberOfDiamonds = 1,
  isToday,
  isClaimed,
  numberOfCoins,
}: {
  index?: number;
  isToday?: boolean;
  numberOfDiamonds?: number;
  numberOfCoins?: number;
  isClaimed?: boolean;
}) {
  const { t } = useTranslation('common');
  return (
    <div
      className={twMerge(
        'border-[3px] border-[#FFE895] bg-[#EBCE66] w-[172px] h-full rounded-[30px] pt-2',
        'flex items-center flex-col relative overflow-hidden select-none',
      )}
    >
      <h6 className="text-[#C88400] text-[24px] z-50 font-bold">
        {isToday ? t('daily_gift.today') : `${t('daily_gift.day')} ${index}`}
      </h6>
      {isClaimed ? (
        <div className="absolute inset-0 flex-1 flex justify-center items-center">
          <Image src={checkBtn} alt="" className="w-[64px] h-[45px]" />
        </div>
      ) : (
        <div className="absolute inset-0 flex-1 flex justify-center items-center">
          <m.div
            className="absolute z-10 w-[484px] h-[484px]"
            animate={{ rotate: [0, 360] }}
            transition={
              isToday
                ? { duration: 8, repeat: Number.POSITIVE_INFINITY, ease: 'linear' }
                : undefined
            }
          >
            <Image src={bigShineImg} alt="" className="w-full h-full opacity-40" />
          </m.div>
          <div className="z-20">
            {numberOfCoins ? (
              <Image src={coinsImg} alt="" className="w-[58px] h-[51px]" />
            ) : (
              <Image src={diamondsImg} alt="diamonds" className="w-[108px] h-[83px]" />
            )}

            <p className="text-[#C88400] text-[22px] text-right -translate-y-3">
              x{numberOfCoins || numberOfDiamonds}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default function DailyGiftModal() {
  const { t } = useTranslation('common');
  const { scaleRatio } = useScaleByMinWidth(800);
  const { user } = useAuth();

  const [isClaimed, setIsClaimed] = useState(true);
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), []);

  const { data: dailyRewardsRes, refetch: refetchList } = useQuery(DailyRewards, {
    context: { v2: true },
  });

  const [claimReward, { loading: loadingClaim }] = useMutation(ViewerDailyRewardClaim, {
    context: { v2: true },
  });

  const finalDayIndex = 7;
  const dailyRewardsData = dailyRewardsRes?.dailyRewards || [];
  const todayIndex =
    (dailyRewardsData.find((dailyReward) => !dailyReward.viewerClaimed)?.dayNumber ||
      finalDayIndex + 1) - (isClaimed ? 1 : 0);

  const finalDayReward = dailyRewardsData.find(
    (dailyReward) => dailyReward.dayNumber === finalDayIndex,
  );

  const onClaim = useCallback(async () => {
    if (loadingClaim) return;

    await claimReward({
      onCompleted: (data) => {
        const errors = data.viewerDailyRewardClaim?.userErrors;
        if (errors?.length) {
          toast.error(errors[0]?.message || t('daily_gift.claim_failed'));
          return;
        }

        setIsClaimed(true);
      },
      onError: (error) => {
        toast.error(error.message || t('daily_gift.claim_failed'));
      },
    });
  }, [claimReward, loadingClaim, t]);

  useEffect(() => {
    if (user?.currentLearnerProfile?.canClaimDailyReward) {
      setIsClaimed(false);
      setOpen(true);
    }
  }, [user?.currentLearnerProfile?.canClaimDailyReward]);

  if (!open) return null;

  return (
    <div
      className={twMerge(
        'fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[9999]',
        DinRoundProFont.className,
        '',
      )}
    >
      <div
        style={{
          transform: `scale(${scaleRatio})`,
        }}
      >
        <BgImage
          src={background}
          alt=""
          imageClassName="w-full h-full"
          wrapClassName={twMerge('w-[727px] h-[602px]')}
          className=""
        >
          <div className="relative flex flex-col items-center w-full h-full">
            <h2 className={twMerge('text-[#FEFEFE] text-[42px] absolute top-[24px]')}>
              {t('daily_gift.daily_gift')}
            </h2>

            <MotionImage
              src={closeIcon}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1 }}
              alt=""
              width={72}
              height={72}
              className="absolute top-[40px] -right-[4px] cursor-pointer"
              onClick={onClose}
            />

            <div className="absolute top-[110px] grid grid-cols-3 gap-3">
              <div className="col-span-2 grid grid-cols-2 gap-3">
                {dailyRewardsData.map((dailyReward) => {
                  if (dailyReward.dayNumber === finalDayIndex) return null;
                  return (
                    <NormalDayGift
                      key={dailyReward.id}
                      isClaimed={dailyReward.viewerClaimed}
                      index={dailyReward.dayNumber}
                      isToday={dailyReward.dayNumber === todayIndex}
                      numberOfCoins={dailyReward.coin ?? 0}
                      numberOfDiamonds={dailyReward.diamond ?? 0}
                    />
                  );
                })}
              </div>
              <div className="col-span-1">
                <FinalDayGift
                  index={finalDayIndex}
                  isClaimed={finalDayReward?.viewerClaimed}
                  isToday={todayIndex === finalDayIndex}
                  numberOfDiamonds={finalDayReward?.diamond ?? 0}
                  numberOfCoins={finalDayReward?.coin ?? 0}
                  key={finalDayReward?.id || finalDayIndex}
                />
              </div>
            </div>

            {isClaimed ? (
              <BgImage
                src={claimDisabledBtn}
                alt=""
                wrapClassName="w-[235px] h-[86px] cursor-pointer absolute -bottom-2"
                className="w-full h-full flex justify-center items-center"
              >
                <p className="text-center text-[#D4D4D4] text-[42px] font-black">
                  {t('daily_gift.claim')}
                </p>
              </BgImage>
            ) : (
              <m.div
                animate={{ scale: [0.97, 1.03, 0.97] }}
                transition={{ duration: 2, repeat: Number.POSITIVE_INFINITY, ease: 'easeInOut' }}
                onClick={onClaim}
                className="cursor-pointer absolute -bottom-2 "
              >
                <BgImage
                  src={claimActiveBtn}
                  alt="claim"
                  className="w-full h-full flex justify-center items-center"
                  wrapClassName="w-[235px] h-[86px]"
                >
                  <div
                    data-text={t('daily_gift.claim')}
                    className={twMerge(
                      'text-center text-[42px] font-bold mt-1',
                      // styles['outside-stroke'],
                    )}
                    style={{
                      background: 'linear-gradient(180deg, #FFFEFD 0%, #D4FD95 100%)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      // paintOrder: 'stroke fill',
                      // WebkitTextStroke: '2px #397303',
                    }}
                  >
                    {t('daily_gift.claim')}
                  </div>
                </BgImage>
              </m.div>
            )}
          </div>
        </BgImage>
      </div>
    </div>
  );
}
