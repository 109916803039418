import MotionImage from '@/components/common/MotionImage';
import bgStreak from '@/components/exam/ExamHomeView/assets/diamond-streak-modal.png';
import diamondStreak from '@/components/exam/ExamHomeView/assets/diamond-streak.png';
import { Popover, useModal } from '@flyer/components';
import { BgImage } from '@flyer/components/next';
import { useAtomValue } from 'jotai';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import Lottie from 'lottie-react';
import ItemMenu from '@/components/exam/ExamHomeView/ExamHeader/ItemMenu';
import ItemDiamond from '@/components/exam/ExamHomeView/ExamHeader/ItemDiamond';
import { useAuth } from '@/providers/auth';
import BookIcon from '@/assets/icons/ic-book.png';
import SpeakingIcon from '@/assets/icons/ic-speaking.png';
import VocabIcon from '@/assets/icons/ic-vocab.png';
import WritingIcon from '@/assets/icons/ic-writing.png';
import menuProfileBackground from '@/assets/images/bg-menu-profile.png';
import profileBackground from '@/assets/images/bg-profile-1.png';
import ChangeProfileIcon from '@/assets/svg/ic-change-profile.svg';
import SettingProfileIcon from '@/assets/svg/ic-profile-setting.svg';
import { Avatar, Button, DropDown } from '@/components/common';
import { Item } from '@/components/common/Dropdown/DropDown';
import BellIcon from '@/components/exam/ExamHomeView/assets/icon/ic_bell.png';
import JoinChallengePopover from '@/components/exam/ExamHomeView/JoinChallengePopover';
import { ModalGetStreakLazy, NoAccessPopupLazy, NotificationPopupLazy } from '@/components/popups';
import ROUTES from '@/lib/routes';
import getMediaUrl, { DinRoundProFont } from '@/lib/utils';
import { testLevelAtomResponse, totalNotification } from '@/store/home';
import { twMerge } from 'tailwind-merge';
import { WritingTestLevelQuery } from '@/__generated__/graphql';
import RankingIcon from '../assets/icon_trophy.png';
import BgProfileNav from '../assets/profile-nav-bg.png';
import practiceAnimation from '../assets/animation/practice.json';
import iconStar from '../assets/star.png';
import iconDiamonds from '../assets/icon-diamonds.png';
import MissionIcon from '../assets/icon-mission.png';
import SchoolIcon from '../assets/icon-school.png';
import HeaderPopover from './HeaderPopover';

export default function HeaderDesktop({ dataWriting }: { dataWriting?: WritingTestLevelQuery }) {
  const { t } = useTranslation('common');
  const { t: popoverTranslation } = useTranslation('popover');
  const router = useRouter();
  const { currentLearnerProfile, user } = useAuth();
  const data = useAtomValue(testLevelAtomResponse);
  const count = useAtomValue(totalNotification);

  const { toggleModal: toggleNotification } = useModal(NotificationPopupLazy);
  const { toggleModal: toggleAccess } = useModal(NoAccessPopupLazy);

  const dataSpeaking = useMemo(() => {
    return data?.testLevels
      ?.filter((item) => item?.speaking_check_points_aggregate?.aggregate?.count)
      .map((el) => ({ ...el, title: el.name }));
  }, [data]);

  const dataWritingMemo = useMemo(() => {
    return (
      dataWriting?.testLevels.nodes
        ?.filter((item) => !item.closed)
        .map((el) => ({ ...el, title: el.name || '' })) || []
    );
  }, [dataWriting]);

  const dataVocabulary = useMemo(() => {
    return data?.testLevels
      ?.filter((item) => item?.vocabulary_check_points_aggregate?.aggregate?.count)
      .map((el) => ({ ...el, title: el.name }));
  }, [data]);

  const handleClickSpeaking = useCallback(
    (item: Item) => {
      if (item.slug === 'ielts') {
        void router.push(ROUTES.IELTS_SPEAKING_CHECK_POINT_LIST);
      } else {
        void router.push(`${ROUTES.REDIRECT_TO_TEST_LEVEL(item.id, 's')}`);
      }
    },
    [router],
  );

  const handleClickWriting = useCallback(
    (item: Item) => {
      if (item?.slug) {
        void router.push(`/testlevels/writing/${item.slug}/1`);
      }
    },
    [router],
  );

  const handleClickVocabulary = useCallback(
    (item: Item) => {
      void router.push(`${ROUTES.REDIRECT_TO_TEST_LEVEL(item.id, 'v')}`);
    },
    [router],
  );

  const handleHistoryClick = useCallback(() => {
    if (!user) {
      toggleAccess();
      return;
    }
    void router.push(`${ROUTES.HISTORY_EXAM}`);
  }, [router, toggleAccess, user]);

  const handleRankingClick = useCallback(() => {
    void router.push(`${ROUTES.HISTORY_RANKING}`);
  }, [router]);

  const handleSchoolClick = useCallback(() => {
    void router.push(`${ROUTES.MY_SCHOOL}`);
  }, [router]);

  const handleMissionClick = useCallback(() => {
    void router.push(`${ROUTES.MISSION}`);
  }, [router]);

  const handlerClickViewPractice = useCallback(() => {
    void router.push(`${ROUTES.LIBRARY}`);
  }, [router]);

  const linkActionDiamond = useMemo(() => {
    const dontShowDiamond = currentLearnerProfile?.domains?.some((el) => !el.showDiamond);
    if (dontShowDiamond) {
      return '';
    }
    return ROUTES.DIAMONDS_PREVIEW;
  }, [currentLearnerProfile?.domains]);

  const listActionLeft = [
    {
      title: 'writing',
      hoverText: popoverTranslation('menu_hover_text.writing'),
      listItems: dataWritingMemo,
      imageIcon: WritingIcon,
      onClick: handleClickWriting,
      iconClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
    {
      title: 'speaking',
      hoverText: popoverTranslation('menu_hover_text.speaking'),
      listItems: dataSpeaking,
      imageIcon: SpeakingIcon,
      onClick: handleClickSpeaking,
      iconClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
    {
      title: 'vocabulary',
      hoverText: popoverTranslation('menu_hover_text.vocabulary'),
      listItems: dataVocabulary,
      imageIcon: VocabIcon,
      onClick: handleClickVocabulary,
      iconClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
    {
      title: t('label.Practice'),
      hoverText: popoverTranslation('menu_hover_text.practice'),
      imageIcon: (
        <Lottie
          animationData={practiceAnimation}
          loop
          className="sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]"
        />
      ),
      isGif: true,
      onClick: handlerClickViewPractice,
      showNew: true,
      iconsClassName: '',
      buttonStyle: { background: 'linear-gradient(180deg, #F1BF29 0%, #D56104 100%)' },
    },
  ];

  const listActionRight = [
    {
      title: t('exam.ranking'),
      hoverText: popoverTranslation('menu_hover_text.ranking'),
      imageIcon: RankingIcon,
      onClick: handleRankingClick,
      showNew: false,
      iconsClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
    {
      title: t('label.history'),
      hoverText: popoverTranslation('menu_hover_text.history'),
      imageIcon: BookIcon,
      onClick: handleHistoryClick,
      showNew: false,
      iconsClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
    {
      title: t('practice_libraries.my_school'),
      hoverText: t('practice_libraries.school_tooltip'),
      imageIcon: SchoolIcon,
      onClick: handleSchoolClick,
      showNew: false,
      iconsClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
    {
      title: t('practice_libraries.mission'),
      hoverText: t('practice_libraries.mission_tooltip'),
      imageIcon: MissionIcon,
      onClick: handleMissionClick,
      showNew: false,
      iconsClassName:
        'sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[45px] xl:h-[45px] 2xl:w-[54px] 2xl:h-[57px]',
    },
  ];
  const { toggleModal: toggleShowStreak } = useModal(ModalGetStreakLazy);

  return (
    <>
      <div data-test-id="left-item" className="flex items-center">
        <div className="flex flex-row gap-[4px] xl:gap-[8px]">
          <JoinChallengePopover />
          {listActionLeft.map(
            ({
              title,
              listItems,
              imageIcon,
              isGif,
              onClick,
              iconClassName,
              showNew,
              iconsClassName,
              buttonStyle,
              hoverText,
            }) => {
              if (!listItems) {
                return (
                  <HeaderPopover hoverText={hoverText} key={title}>
                    <ItemMenu
                      key={title}
                      title={title}
                      onClick={onClick as () => void}
                      image={imageIcon}
                      isGif={isGif}
                      showNew={showNew || false}
                      iconsClassName={iconsClassName || ''}
                      buttonStyle={buttonStyle}
                    />
                  </HeaderPopover>
                );
              }
              return (
                <HeaderPopover hoverText={hoverText} key={title}>
                  <DropDown
                    key={title}
                    items={listItems}
                    title={title}
                    Icon={imageIcon}
                    classNameButton="sm:w-[4.5vw] sm:h-[4.5vw] md:w-[40px] md:h-[40px] lg:w-[55px] lg:h-[52px] xl:w-[72px] xl:!h-[69px] 2xl:w-[93px] 2xl:!h-[86px] xl:text-[9px]"
                    classNameWrapIconText="lg:h-[54px] xl:h-[71px] 2xl:h-[80px] md:h-[37px] sm:h-[4.5vw] sm:mt-[-1vw] xl:-mt-[18px]"
                    classNameWrapText="sm:top-[4px] md:top-[5px] lg:top-[3px] xl:top-[3px]"
                    textClassName="text-[9px] sm:text-[0.7vw] lg:text-[7px] lg:leading-[9px] xl:text-[9px]"
                    iconPolygonClassName="w-[10px] sm:w-[6px] xl:w-[9px]"
                    classNameIcon={iconClassName}
                    onChange={onClick}
                  />
                </HeaderPopover>
              );
            },
          )}
        </div>
        <div className="sm:ml-[10px] sm:mr-[10px] 3xl:mr-[47px] 3xl:ml-[37px] relative">
          <HeaderPopover hoverText={popoverTranslation('menu_hover_text.experience')}>
            <ItemDiamond
              showNew={false}
              linkAction="home#"
              title={t('exam.experience')}
              icon={iconStar}
              numberValue={currentLearnerProfile?.attendance || 0}
              iconClassName="w-[20px] h-[20px] sm:w-[1.5vw] sm:h-[1.5vw] xl:w-[25px] xl:h-[25px] 2xl:w-[40px] 2xl:h-[40px]"
            />
          </HeaderPopover>
          {currentLearnerProfile && !currentLearnerProfile?.isAnonymous && (
            <BgImage
              wrapClassName="w-[7vw] absolute bottom-[-200%] z-[100] cursor-pointer w-full"
              className="flex flex-col justify-center items-center"
              alt="flyer"
              src={bgStreak}
              onClick={toggleShowStreak}
            >
              <div className="w-full flex flex-col justify-center items-center">
                <MotionImage alt="flyer" src={diamondStreak} className="w-[50%]" />
                <p
                  className={twMerge(
                    '[-webkit-text-stroke-color:#692BA7] [-webkit-text-stroke-width:0.5px] text-[2vw] xl:text-[1.5vw] 2xl:text-2xl text-white font-semibold mt-[-14px]',
                    DinRoundProFont.className,
                  )}
                >
                  {currentLearnerProfile?.streakData?.currentStreak?.count || 0}
                </p>
              </div>
            </BgImage>
          )}
        </div>
      </div>
      <div className="center-image flex justify-center z-[10]">
        <div className="item-4 w-[150px] lg:w-[200px] xl:w-[214px] 2xl:w-[252px] mt-[-1.25rem]">
          {currentLearnerProfile ? (
            <div className="flex flex-col xl:flex-row">
              <BgImage alt="Profile" src={profileBackground} wrapClassName="w-fit sm:max-w-[unset]">
                <BgImage
                  src={menuProfileBackground}
                  alt="Menu profile"
                  wrapClassName="bottom-[10px] sm:bottom-[20px] !absolute w-full select-none"
                  imageClassName="sm:w-full"
                >
                  <Link
                    className="w-[10%] absolute left-2 sm:left-6 top-[50%] inline-block translate-y-[-50%] [&>svg>*]:hover:fill-[#10E7FD] "
                    href={ROUTES.PROFILE}
                  >
                    <ChangeProfileIcon className="w-full [&>*]:transition-colors [&>*]:duration-300" />
                  </Link>
                  <Link
                    className="w-[8%] absolute right-2 sm:right-6 top-[50%] inline-block translate-y-[-50%] [&>svg>*]:hover:fill-[#10E7FD]"
                    href={ROUTES.EDIT_PROFILE}
                  >
                    <SettingProfileIcon className="w-full [&>*]:transition-colors [&>*]:duration-300" />
                  </Link>
                  <div className="absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[10%]">
                    <Link href={ROUTES.EDIT_PROFILE}>
                      <Avatar
                        src={
                          currentLearnerProfile?.avatarUrl
                            ? getMediaUrl(currentLearnerProfile?.avatarUrl, 'avatar')
                            : undefined
                        }
                        className="sm:w-[25px] sm:h-[25px] lg:rounded-full sm:rounded-[10px] md:w-[40px] md:h-[40px] lg:w-[90%] lg:h-[90%]"
                      />
                    </Link>
                  </div>
                  <div className="absolute left-[50%] translate-x-[-50%] w-full text-center bottom-[-70%] text-slate-50 z-10">
                    <Popover
                      contentClassName="sm:text-[10px] xl:text-[14px] w-[200px] sm:w-full"
                      content={currentLearnerProfile?.displayName}
                      onlyShowOnEllipsis
                    >
                      <span className="sm:text-[10px] xl:text-[14px] text text-one-line uppercase font-extrabold">
                        {currentLearnerProfile?.displayName}
                      </span>
                    </Popover>
                  </div>
                </BgImage>
              </BgImage>
            </div>
          ) : (
            <div className="after-login md:mt-[-5px] xl:w-[214px] 2xl:w-[252px]">
              <BgImage alt="Profile" src={BgProfileNav}>
                <div className="lex flex-col xl:flex-col userNot-login h-full justify-center items-center sm:p-[15px] md:p-6">
                  <Link href={ROUTES.SIGN_IN()} className="w-full">
                    <Button
                      className="sm:h-[20px] lg:h-[30px] xl:!h-[35px] 2xl:!h-[42px]"
                      classNameText="flex items-center justify-center sm:text-[7px] md:text-[10px] 2xl:text-[14px] p-3 uppercase"
                    >
                      {t('exam.sign_in')}
                    </Button>
                  </Link>
                  <div className="h-[7px]" />
                  <Link href={ROUTES.SIGN_UP()} className="w-full">
                    <Button
                      colorScheme="red"
                      className="sm:h-[20px] lg:h-[30px] xl:!h-[35px] 2xl:!h-[42px]"
                      classNameText="flex items-center justify-center sm:text-[7px] md:text-[10px] 2xl:text-[14px] p-3 uppercase"
                    >
                      {t('exam.sign_up')}
                    </Button>
                  </Link>
                </div>
              </BgImage>
            </div>
          )}
        </div>
      </div>
      <div className="right-item flex items-center">
        <div className="sm:ml-[10px] sm:mr-[10px] 3xl:mr-[47px] 3xl:ml-[37px]">
          <HeaderPopover hoverText={popoverTranslation('menu_hover_text.diamond')}>
            <ItemDiamond
              showNew={false}
              isCanActionLink={user?.isSubscribing}
              linkAction={linkActionDiamond}
              title={t('diamonds.diamond')}
              icon={iconDiamonds}
              numberValue={user?.diamond || 0}
              iconClassName="w-[20px] h-[20px] sm:w-[2vw] sm:h-[1.5vw] xl:w-[32px] xl:h-[25px] 2xl:w-[44px] 2xl:h-[35px]"
            />
          </HeaderPopover>
        </div>
        <div className="flex flex-row gap-[8px]">
          {listActionRight.map(
            ({ title, onClick, imageIcon, showNew, iconsClassName, hoverText }) => {
              return (
                <HeaderPopover hoverText={hoverText} key={title}>
                  <ItemMenu
                    key={title}
                    title={title}
                    onClick={onClick}
                    image={imageIcon}
                    showNew={showNew}
                    iconsClassName={iconsClassName}
                  />
                </HeaderPopover>
              );
            },
          )}
        </div>
        <div className="item-8 sm:ml-[8px]">
          <Button
            shape="square"
            className="z-[10] lg:rounded-[16px] sm:rounded-[8px] uppercase w-[82px] h-[72px] sm:w-[4.5vw] sm:h-[4.5vw] md:w-[5vw] md:h-[5vw] lg:w-[55px] lg:h-[52px] xl:w-[72px] xl:!h-[69px] 2xl:w-[93px] 2xl:!h-[86px]"
            variant="polish"
            colorScheme="indigo"
            onClick={toggleNotification}
            animation={false}
          >
            <div className="wrap-content item flex flex-col sm:mt-[-1vw] md:-mt-2 lg:-mt-[10px]">
              <div className="relative flex justify-center">
                {count > 0 && (
                  <div className="notify-number bg-home-notify-icon bg-contain absolute right-[20px] sm:right-[9px] sm:w-[13px] sm:h-[13px] md:text-[9px] md:w-[20px] md:h-[20px] md:right-1 md:top-1 xl:top-[8px] xl:right-[12px] 2xl:top-[8px] 2xl:right-[12px] lg:w-[20px] lg:top-[6px] lg:h-[20px] lg:right-1 lg:text-[10px] xl:w-[21px] xl:h-[21px] leading-[2] xl:text-[9px] z-[10]">
                    {count}
                  </div>
                )}
                <Image
                  src={BellIcon}
                  alt="flyer"
                  className="w-[59.0869%] h-[69.5652%] sm:w-[3.5vw] sm:h-[3.5vw] md:w-[4vw] md:h-[4vw] lg:h-[45px] lg:w-[45px] xl:w-[54px] xl:h-[54px] 2xl:w-[54px] 2xl:h-[57px]"
                />
              </div>
              <span className="relative flex items-center justify-center text-[9px] sm:pt-[1.4vw] sm:text-[0.7vw] md:pt-[2px] lg:pt-[2px] lg:text-[7px] xl:text-[9px] 2xl:pt-[6px]">
                {t('exam.notification')}
              </span>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}
