import time from '@flyer/utils/time';
import { useState, useEffect } from 'react';

export enum KetPetSpeakingSteps {
  Intro = 'intro',
  TestMic = 'test-mic',
  QuestionList = 'question-list',
  Exam = 'exam',
  TheEnd = 'the-end',
  Review = 'review',
}

export enum KetSetSpeakingGradeMethodEnum {
  Teacher = 'teacher',
  AI = 'AI',
}

export enum KetSetSpeakingTestMicStatus {
  RecordReady = 'RecordReady',
  Recording = 'Recording',
  RecordPaused = 'RecordPaused',
  RecordFinished = 'RecordFinished',
  AudioReady = 'AudioReady',
  AudioPlaying = 'AudioPlaying',
  AudioPaused = 'AudioPaused',
  AudioFinished = 'AudioFinished',
}

export enum MediaStatus {
  Loading = 'Loading',
  Ready = 'Ready',
  Playing = 'Playing',
  Paused = 'Paused',
  Finished = 'Finished',
}

export const convertTimeToMinSecond = (second: number) => {
  const minutes = Math.floor(second / 60);
  const seconds = Math.floor(second % 60);
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const convertDurationToSecond = (duration: string) => {
  return time.duration(duration).asSeconds();
};

export const useScaleByMinWidth = (minWidth: number) => {
  const [scaleRatio, setScaleRatio] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      const newScaleRatio = window.innerWidth >= minWidth ? 1 : window.innerWidth / minWidth;
      setScaleRatio(newScaleRatio);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => window.removeEventListener('resize', updateScale);
  }, [minWidth]);

  return { scaleRatio };
};

export const useScaleByMinHeight = (minHeight: number) => {
  const [scaleRatio, setScaleRatio] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      const newScaleRatio = window.innerHeight >= minHeight ? 1 : window.innerHeight / minHeight;
      setScaleRatio(newScaleRatio);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => window.removeEventListener('resize', updateScale);
  }, [minHeight]);

  return { scaleRatio };
};
